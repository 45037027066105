import React from 'react';
import './Home.css'; // Import the CSS file
import riyana from '../images/riyana.jpg';

function Home() {
  return (
    <div className="home-container" style = {{backgroundColor: 'transparent'}}>
      {/* Add the image on the left and text on the right */}
      <div className="home-content">
        <img 
          src={riyana}
          alt="Profile" 
          className="home-image"
        />
        <div className="col-md-4"></div>
        <div className="home-text">
          <h1>Hello, I'm Riyana. 👋</h1>
          <h2>Welcome to My Website!</h2>
        </div>
      </div>
    </div>
  );
}

export default Home;
